
.app-card
  @extend .shadow-sm
  margin-bottom: 1em
  padding: 2em
  width: 100%
  border-radius: .3em
  // allow using "notification" class to override color styles
  &:not(.notification)
    background-color: $white

