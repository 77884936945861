@charset "utf-8";
@use "sass:math";

// Bootstrap base file created via these instructions:
// https://getbootstrap.com/docs/5.0/customize/sass/#importing

// Configuration
@import "~bootstrap/scss/functions";

// you can override variables here:
$primary: #27AF71;  // change primary color to green
$secondary: #7EAA97;  // change secondary color to gray
$body-bg: #DEDCE2;
$body-color: black;

$dark-gradient: #34433A;
$dark-gradient-state: #4B6056;

// Material dashboard theme
// Custom Variables
@import "../material-dashboard/scss/material-dashboard/custom/variables";

// Theme Variables
@import "../material-dashboard/scss/material-dashboard/variables";

@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/variables-dark";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/maps";
@import "~bootstrap/scss/utilities";

// Layout & components
@import "~bootstrap/scss/root";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/containers";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/tables";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/transitions";
@import "~bootstrap/scss/dropdown";
@import "~bootstrap/scss/button-group";
@import "~bootstrap/scss/nav";
@import "~bootstrap/scss/navbar";
@import "~bootstrap/scss/card";
@import "~bootstrap/scss/accordion";
@import "~bootstrap/scss/breadcrumb";
@import "~bootstrap/scss/pagination";
@import "~bootstrap/scss/badge";
@import "~bootstrap/scss/alert";
@import "~bootstrap/scss/progress";
@import "~bootstrap/scss/list-group";
@import "~bootstrap/scss/close";
@import "~bootstrap/scss/toasts";
@import "~bootstrap/scss/modal";
@import "~bootstrap/scss/tooltip";
@import "~bootstrap/scss/popover";
@import "~bootstrap/scss/carousel";
@import "~bootstrap/scss/spinners";

// Helpers
@import "~bootstrap/scss/helpers";

// Utilities
@import "~bootstrap/scss/utilities/api";

// Material theme Core
@import "../material-dashboard/scss/material-dashboard/theme";

// Material Custom Variables
@import "../material-dashboard/scss/material-dashboard/custom/styles";

:root {
  --primary: #{$primary};
  --danger: #{$danger};
  --success: #{$success};
  --gray: #{$gray-500};
}

// Pegasus
@import "app/bootstrap/_all";
@import "pegasus/bootstrap";

